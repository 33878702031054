<template>
	<v-container fluid fill-height class="cw-app-wrapper service-info">
		<div class="cw-service-section">
			<div class="wrap_section">
				<v-icon color="primary">mdi-message-alert</v-icon>
				<h1 class="headtitle">{{ guideData.title }}</h1>
				<p class="subtitle" v-html="guideData.msg"></p>
			</div>
			<div class="wrap_time" v-if="guideData.start !== null">
				{{ this.$i18n.t('common.error.check.time') }} : {{ guideData.start }}
				<br />
				~ {{ guideData.end }}
			</div>
		</div>
	</v-container>
</template>

<script>
/**
 * 점검시 사용하는 화면입니다.
 * @description 기본값은 타이틀과 메세지만 출력된다.
 *              (새로고침시, store에 data가 없는 경우)
 */
import { registerCallbackToApp } from '@/utils/navigation'
import moment from 'moment'

export default {
	name: 'ServiceInfo',
	data() {
		return {
			guideData: {
				title: this.$i18n.t('common.error.check.title'),
				msg: this.$i18n.t('common.error.check.text'),
				start: null,
				end: null
			}
		}
	},
	created() {
		this.initAppPage()
	},
	mounted() {
		const tempGuideData = this.$store.getters['common/getGuideData']
		if (tempGuideData.start !== null) {
			// o월 o일(o요일) 00:00
			this.guideData.start = moment(tempGuideData.start)
				.locale(`${process.env.VUE_APP_I18N_LOCALE}`)
				.format('MMMM Do(ddd) HH:mm')
		}
		if (tempGuideData.end !== null) {
			// o월 o일(o요일) 00:00
			this.guideData.end = moment(tempGuideData.end)
				.locale(`${process.env.VUE_APP_I18N_LOCALE}`)
				.format('MMMM Do(ddd) HH:mm')
		}
		if (tempGuideData.title !== null) {
			this.guideData.title = tempGuideData.title
		}
		if (tempGuideData.msg !== null) {
			this.guideData.msg = tempGuideData.msg
		}
	},
	methods: {
		initAppPage() {
			registerCallbackToApp({})
		}
	}
}
</script>

<style scoped></style>
